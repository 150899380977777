<template>
  <div class="interpreting">
    <Banner/>
    <div class="firstPlate">
      <div class="solutionPublicTitle">
        <p>方案优势</p>
        <p>SOLUTION ADVANTAGE</p>
      </div>
      <div class="hy_fays_body">
        <div class="hy_advantage">
          <p class="advantage_imgs">
            <img src="@/assets/imgs/solution/asr_sys.png" alt="实时精准的识别系统">
          </p>
          <div class="advantage_info">
            <b>实时精准的识别系统</b>
            <span>拥有业界领先的技术，识别准确率高达97%</span>
          </div>
        </div>
        <div class="hy_advantage">
          <p class="advantage_imgs">
            <img src="@/assets/imgs/solution/tech.png" alt="深厚的技术积累">
          </p>
          <div class="advantage_info">
            <b>深厚的技术积累</b>
            <span>曾五次获得机器翻译/语音翻译世界冠军</span>
          </div>
        </div>
        <div class="hy_advantage">
          <p class="advantage_imgs">
            <img src="@/assets/imgs/solution/e2e.png" alt="业界领先的端到端技术">
          </p>
          <div class="advantage_info">
            <b>业界领先的端到端技术</b>
            <span>支持汉语到英日韩等多个语种的翻译</span>
          </div>
        </div>
        <div class="hy_advantage">
          <p class="advantage_imgs">
            <img src="@/assets/imgs/solution/privatization.png" alt="支持私有化部署">
          </p>
          <div class="advantage_info">
            <b>支持私有化部署</b>
            <span>对硬件要求低且支持跨平台部署</span>
          </div>
        </div>
      </div>
    </div>
    <div class="secondPlate">
      <div class="solutionPublicTitle">
        <p>应用场景</p>
        <p>APPLICATION SCENARIOS</p>
      </div>
      <div class="appbox white_bg">
        <div class="appboxCenter">
          <div class=""><img src="@/assets/imgs/solution/meeting.png" alt=""></div>
          <div class="hy_yyjs_infos">
            <div class="yyjs_title">
              <span>大型会议演讲</span>
              <b></b>
            </div>
            <div class="yyjs_details">
              <h6>快速准确的机器同传</h6>
              <h6>为参会者带来优质体验</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="appbox gray_bg">
        <div class="appboxCenter">
          <div class="hy_yyjs_infos">
            <div class="yyjs_title">
              <span>跨国视频会议</span>
              <b></b>
            </div>
            <div class="yyjs_details">
              <h6>与国际友人沟通零障碍</h6>
              <h6>让跨语言交流得心应手</h6>
            </div>
          </div>
          <div class=""><img src="@/assets/imgs/solution/video_conference.png" alt=""></div>
        </div>
      </div>
      <div class="appbox white_bg">
        <div class="appboxCenter">
          <div class=""><img src="@/assets/imgs/solution/public_class.png" alt=""></div>
          <div class="hy_yyjs_infos">
            <div class="yyjs_title">
              <span>行业公开课</span>
              <b></b>
            </div>
            <div class="yyjs_details">
              <h6>专业领域的语音翻译</h6>
              <h6>提高课程的质量</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="thirdPlate">
      <div class="solutionPublicTitle">
        <p>合作伙伴</p>
        <p>COOPERATIVE PARTNER</p>
      </div>
      <div class="hy_hzhb_body">
        <div><img src="@/assets/imgs/qhmz_university.jpg" alt="天津大学"></div> <!-- 青海民族大学 -->
        <div><img src="@/assets/imgs/tjlg_university.png" alt="天津理工大学"></div>  <!-- 天津理工大学 -->
      </div>
    </div>
    <div class="hy_index_gray">
      <div class="hy_index_hzzx">
        <p><span>点击下方按钮，获得我们的专属支持</span></p>
        <div><button><a href="mailto:bd@huiyan-tech.com" target="_blank">合作咨询</a></button></div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner"
export default {
  name: "Interpreting",
  components: {
    Banner,
  },
}
</script>

<style lang="less" scoped>
.hy_fays_body {
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  .hy_advantage {
    height: 100%;
    width: auto;
    margin-right: 125px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .advantage_imgs {
      margin-bottom: 20px;
      display: flex;
    }
    .advantage_imgs, .advantage_imgs img {
      width: 100px;
      height: 100px;
    }
    .advantage_info {
      display: flex;
      flex-direction: column;
      align-items: center;
      b {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        line-height: 36px;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        line-height: 22px;
      }
    }
  }
  .hy_advantage:nth-of-type(4) {
    margin-right: 0;
  }
}
.appbox {
  width: 100%;
  height: 500px;
  .appboxCenter {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    img {
      width: 440px;
      height: 330px;
    }
    .hy_yyjs_infos .yyjs_title{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      height: 50px;
    }
    .hy_yyjs_infos .yyjs_title span{
      color: #000;
      font-size: 30px;
      font-weight: 500;
    }
    .hy_yyjs_infos .yyjs_title b{
      width: 50px;
      height: 3px;
      background-color: #155FA1;
      border-radius: 2px;
    }
    .hy_yyjs_infos .yyjs_details{
      margin-top: 40px;
    }
    .hy_yyjs_infos .yyjs_details p{
      font-size: 24px;
      font-weight: 400;
      color: #000000;
      line-height: 33px;
      text-align: center;
    }
    .hy_yyjs_infos h6{
      font-size: 18px;
      font-weight: 300;
      color: #383737;
      text-align: center;
      line-height: 38px;
    }
  }
}
.gray_bg {
  background: #F4F5FA;
}
.secondPlate {
  .hy_index_fajg{
    display: flex;
    justify-content: center;
    .hy_fajg_body{
      width: 1200px;
      height: 598px;
      display: flex;
      background: #FFFFFF;
      border-radius: 38px 0 0 38px;
      border: 1px solid #DADADA;
      position: relative;
      .fajg_body_left{
        width: 678px;
        padding-left: 40px;
        padding-top: 38px;
        margin-right: 42px;
        .fajg_pictures{
          height: 188px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          img{
            width: 215px;
            height: 188px;
          }
        }
        .fajg_architecture{
          margin-top: 10px;
          .fajg_archi_body{
            height: 92px;
            background: #FFFFFF;
            box-shadow: 0 3px 3px 0 rgba(233, 233, 233, 0.5);
            border-radius: 6px;
            border: 1px solid #EBEBEB;
            margin-bottom: 22px;
            display: flex;
            h4{
              flex-shrink: 0;
              width: 58px;
              height: 92px;
              background: #155FA1;
              border-radius: 6px 0 0 6px;
              border: 1px solid #155FA1;
              color: #fff;
              font-weight: 600;
              font-size: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              line-height: 20px;
            }
            .fajg_body_details{
              flex: 1;
              padding: 24px 22px;
              display: flex;
              span{
                width: 130px;
                height: 45px;
                border-radius: 6px;
                border: 1px solid #155FA1;
                font-size: 14px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.85);
                line-height: 45px;
                text-align: center;
                margin-right: 15px;
              }
              span:nth-of-type(4){
                margin-right: 0;
              }
            }
          }
          .fajg_archi_body:nth-of-type(3){
            margin-bottom: 0;
          }
          /* 智慧教育方案架构 */
          .smart_edu_yyc .detail_body{
            width: 180px;
            height: 149px;
            background: #FFFFFF;
            box-shadow: 0 3px 3px 0 rgba(233, 233, 233, 0.5);
            border-radius: 6px;
            border: 1px solid #EBEBEB;
            margin-right: 24px;
          }
          .smart_edu_yyc .detail_body:nth-of-type(3){
            margin-right: 0;
          }
          .smart_edu_yyc{
            height: 149px;
            border: none;
            box-shadow: none;
          }
          .smart_edu_yyc h4{
            height: 149px;
          }
          .smart_edu_yyc p{
            text-align: center;
          }
          .smart_edu_yyc .detail_body_title{
            height: 36px;
            line-height: 36px;
            background: #155FA1;
            border-radius: 6px 6px 0 0;
            color: #fff;
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 10px;
          }
          .smart_edu_yyc .detail_body_info{
            font-size: 15px;
            font-weight: 500;
            color: #464646;
            line-height: 30px;
          }
          .smart_edu_yyc .fajg_body_details{
            padding: 0 0 0 22px;
          }
        }
      }
      .fajg_body_right{
        position: absolute;
        right: 0;
        top: -2px;
        bottom: -2px;
        width: 355px;
        padding: 30px;
        border: 1px solid #155FA1;
        background: #155FA1 url("~@/assets/imgs/solution/archi_bg.png") no-repeat 99px 327px;
        background-size: 234px 234px;
        .archi_instruction{
          height: 252px;
          padding-bottom: 24px;
          border-bottom: 1px dotted #fff;
          margin-bottom: 28px;
        }
        h5{
          font-size: 18px;
          color: #fff;
          font-weight: 400;
        }
        p, li{
          font-size: 16px;
          color: #fff;
          font-weight: 400;
          line-height: 28px;
        }
        li:before{
          content: '';
          width: 4px;
          height: 4px;
          display: inline-block;
          border-radius: 50%;
          background: #fff;
          margin-right: 5px;
          transform: translateY(-50%);
        }
      }
      .smart_edu_fajg{
        background: #155FA1 url("~@/assets/imgs/solution/archi_bg.png") no-repeat 99px 300px;
        background-size: 234px 192px;
        .archi_instruction{
          height: 220px;
        }
      }
    }
    .smart_edu_fajg_body{
      height: 510px;
    }
  }
}
.hy_hzhb_body>div:first-child {
  width: 300px;
}
</style>